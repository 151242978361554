export default `
  query categories($category_uid: String!){
    categories(filters: {
      category_uid: {
        eq: $category_uid
      }
    }) {
      items {
        id
        subcategory_bottom_content
      }
    }
  }
`;
