<template>
  <div>
    <div class="accordion cursor-pointer">
      <div
        @click="toggleAccordion()"
        class="flex justify middle cursor-pointer toggle"
        :aria-expanded="open"
        :aria-controls="`collapse${_uid}`"
        :class="{'is-open': open}"
      >
        <slot name="title" />
      </div>

      <div class="content" v-show="open" :id="`collapse${_uid}`">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  watch: {
    collapse() {
      this.open = this.collapse;
    }
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    collapsible: {
      type: Boolean,
      default() {
        return false;
      }
    },
    collapse: {
      type: Boolean,
      default() {
        return false;
      }
    },
  },
  data() {
    return {
      open: this.isOpen
    }
  },
  created() {
    if(this.collapsible) {
      this.open = true;
    }
  },
  methods: {
    toggleAccordion() {
      this.open = !this.open;
      this.$emit('collapse')
    },
  },
};
</script>
