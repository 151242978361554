





























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import {
  SfSelect,
  SfButton,
} from '@storefront-ui/vue';
import SvgImage from '~/components/General/SvgImage.vue';
import { useUiHelpers, useUiState } from '~/composables';
import { Pagination } from '~/composables/types';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import {SortingModel, SortingOptionsValuesEnum} from '~/modules/catalog/category/composables/useFacet/sortingOptions';
import Select from "~/components/Palmers/BaseComponents/Select/Select.vue";
export default defineComponent({
  components: {
    Select,
    SkeletonLoader,
    SvgImage,
    SfSelect,
    SfButton,
  },
  props: {
    sortBy: {
      type: Object as PropType<SortingModel>,
      required: true,
    },
    pagination: {
      type: Object as PropType<Pagination>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const {
      toggleFilterSidebar, changeToCategoryListView, changeToCategoryGridView, isCategoryGridView,
    } = useUiState();
    const uiHelpers = useUiHelpers();
    const doChangeSorting = (sort: { value: string }) => {
      uiHelpers.changeSorting(sort.value, false);
      emit('reloadProducts');
    };
    return {
      toggleFilterSidebar,
      doChangeSorting,
      ...uiHelpers,
      changeToCategoryListView,
      changeToCategoryGridView,
      isCategoryGridView,
    };
  },
  methods: {
    getSelected() {
      const find = this.sortBy.options.find((option) => option.value === this.sortBy.selected);

      return {
        label: find?.label || this.$i18n.t('Default'),
        value: this.sortBy.selected || SortingOptionsValuesEnum.DEFAULT
      }
    }
  }
});
