import { Aggregation } from '~/modules/GraphQL/types';

export const getProductFilterByCategoryCommand = {
  execute: async (context, variables, search: string = null): Promise<Aggregation[]> => {

    if(search) {
      variables['search'] = search;
    }

    //@ts-ignore
    const { data } = await context.$vsf.$magento.api.getProductFilterByCategory(variables);

    return data?.products?.aggregations || [];
  },
};

export default getProductFilterByCategoryCommand;
