enum RendererTypesEnum {
  RADIO = 'RadioType',
  CHECKBOX = 'CheckboxType',
  SWATCH_COLOR = 'SwatchColorType',
  YES_NO = 'YesNoType',
  SWATCH_SIZE = 'SwatchSizeType',
  PRICE_RANGE = 'PriceRangeType',
  CATEGORY_ID = 'CategoryIdType',
  DEFAULT = 'CheckboxType',
}

export default RendererTypesEnum;
