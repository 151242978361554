



















































































































import {
  defineComponent,
  nextTick,
  onMounted,
  provide,
  ref,
  Ref,
  useContext,
  useFetch,
  watch,
} from '@nuxtjs/composition-api';
import {SfButton, SfFilter, SfHeading, SfRadio, SfSidebar,} from '@storefront-ui/vue';

import {clearAllBodyScrollLocks} from 'body-scroll-lock';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import {FilterEqualTypeInput, useUiHelpers} from '~/composables';
import {getFilterConfig} from '~/modules/catalog/category/config/FiltersConfig';
import SelectedFilters from '~/modules/catalog/category/components/filters/FiltersSidebar/SelectedFilters.vue';
import {getProductFilterByCategoryCommand} from '~/modules/catalog/category/components/filters/command/getProductFilterByCategoryCommand';

import type {Aggregation} from '~/modules/GraphQL/types';
import type {SelectedFiltersInterface} from '~/modules/catalog/category/components/filters/useFilters';
import {useFilters} from '~/modules/catalog/category/components/filters/useFilters';
import Accordion from "~/components/Palmers/BaseComponents/Accordion/Accordion.vue";
import {useTraverseCategory} from '~/modules/catalog/category/helpers/useTraverseCategory';
import {GetProductSearchParams} from "~/modules/catalog/product/types";
import {createProductAttributeFilterInput} from "~/modules/catalog/category/composables/useFacet/input/createProductAttributeFilterInput";
import {createProductAttributeSortInput} from "~/modules/catalog/category/composables/useFacet/input/createProductAttributeSortInput";
import {SortingOptionsValuesEnum} from "~/modules/catalog/category/composables/useFacet/sortingOptions";
import {ignoreAnalyticsParams} from "~/helpers/ignoreAnalyticsParams";


export interface UseFiltersProviderInterface {
  selectedFilters: Ref<SelectedFiltersInterface>,
  filters: Ref<Aggregation[]>,
}


export default defineComponent({
  name: 'CategoryFilters',
  components: {
    SelectedFilters,
    SkeletonLoader,
    Accordion,
    CheckboxType: () => import('~/components/Palmers/Category/Filter/Renderer/CheckboxType.vue'),
    SwatchColorType: () => import('~/components/Palmers/Category/Filter/Renderer/SwatchColorType.vue'),
    SwatchSizeType: () => import('~/components/Palmers/Category/Filter/Renderer/SwatchSizeType.vue'),
    PriceRangeType: () => import('~/components/Palmers/Category/Filter/Renderer/PriceRangeType.vue'),
    RadioType: () => import('~/components/Palmers/Category/Filter/Renderer/RadioType.vue'),
    YesNoType: () => import('~/components/Palmers/Category/Filter/Renderer/YesNoType.vue'),
    CategoryIdType: () => import('~/components/Palmers/Category/Filter/Renderer/CategoryIdType.vue'),
    SfSidebar,
    SfHeading,
    SfFilter,
    SfButton,
    SfRadio,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    catUid: {
      type: [String, Array, undefined],
    },
    getCatLink: {
      type: Function,
      require: true
    },
    term: {
      type: String
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      maxOptionCount: 3
    }
  },
  setup(props, {emit}) {
    const update = ref(0);
    const {activeCategory} = useTraverseCategory();

    const {changeFilters, getFacetsFromURL} = useUiHelpers();
    const removableFilters = ref([]);
    const filters = ref<Aggregation[]>([]);
    const isLoading = ref(true);

    const {
      selectedFilters,
      selectFilter,
      removeFilter,
      isFilterSelected,
      getRemovableFilters,
    } = useFilters();

    const isClearAll = ref(Boolean(Object.keys(selectedFilters.value).length));

    const updateRemovableFilters = () => {
      removableFilters.value = getRemovableFilters(filters.value, selectedFilters.value);
    };

    const doApplyFilters = () => {
      changeFilters(selectedFilters.value, false);
      updateRemovableFilters();
      if (props.isMobile) {
        emit('toggleModal');
      }
      emit('reloadProducts');
    };

    const doRemoveFilter = ({id, value}: { id: string, value: string }) => {
      removeFilter(id, value);
      changeFilters(selectedFilters.value, false);
      updateRemovableFilters();
      emit('reloadProducts');
    };

    const removeByAttributeCode = (code) => {
      selectedFilters.value[code] = [];
      update.value += 1;
    }

    const doClearFilters = () => {
      for (let attribute in selectedFilters.value) {
        if (selectedFilters.value.hasOwnProperty(attribute)) {
          selectedFilters.value[attribute] = [];
        }
      }
      if (props.isMobile) doApplyFilters();
      update.value += 1;
    };

    watch(() => props.isVisible, (newValue) => {
      // disable Storefrontt UI's body scroll lock which is launched when :visible prop on SfSidebar changes
      // two next ticks because SfSidebar uses nextTick aswell, and we want to do something after that tick.
      if (newValue) {
        nextTick(() => nextTick(() => clearAllBodyScrollLocks()));
      }
    });

    const context = useContext();

    const {fetch: fetchFilters} = useFetch(async () => {
      const searchParams = {...getFacetsFromURL()};

      if(props.catUid) {
        searchParams['category_uid'] = props.catUid
      }

      const productSearchParams: GetProductSearchParams = {
        search: props.term ? props.term : '',
        filter: createProductAttributeFilterInput(searchParams as any),
        sort: createProductAttributeSortInput(searchParams.sort || SortingOptionsValuesEnum.DEFAULT)
      };

      if (filters.value && filters.value.length > 0) {
        const filtersData = await getProductFilterByCategoryCommand.execute(context, productSearchParams, props.term);
        const oldPriceFilter = filters.value.filter((filterItem) => filterItem.attribute_code === 'price');

        if (oldPriceFilter.length) {
          filters.value = [
            ...oldPriceFilter,
            ...filtersData.filter((filterItem) => filterItem.attribute_code !== 'price')
          ]
        } else {
          filters.value = filtersData;
        }
      } else {
        filters.value = await getProductFilterByCategoryCommand.execute(context, productSearchParams, props.term);
      }
      updateRemovableFilters();
      isLoading.value = false;
      update.value += 1;
    })

    watch(selectedFilters.value, () => {
      if (!props.isMobile) {
        doApplyFilters();
      }
      isClearAll.value = Boolean(Object.keys(selectedFilters.value).length);
      if (isClearAll.value) {
        let checkLength = false;
        for (let attribute in selectedFilters.value) {
          if (selectedFilters.value.hasOwnProperty(attribute) && Boolean(selectedFilters.value[attribute].length)) {
            checkLength = true;
          }
        }
        isClearAll.value = checkLength;
      }

      fetchFilters();
    })

    provide('UseFiltersProvider', {isFilterSelected, selectedFilters, filters});

    return {
      selectFilter,
      doApplyFilters,
      doRemoveFilter,
      doClearFilters,
      getFilterConfig,
      selectedFilters,
      filters,
      isLoading,
      removableFilters,
      activeCategory,
      isClearAll,
      removeByAttributeCode,
      update
    };
  }
});
