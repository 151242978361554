<template>
  <div class="items-per-page-select navbar">
    <client-only>
      <Select
          :value-key="'value'"
          :options="options"
          :selected="{label: options.find((option) => parseInt(option.value) === parseInt(itemsCount)).label, value: itemsCount}"
          @selectAction="doChangeItemsPerPage($event.value)"
      />
    </client-only>
  </div>
</template>
<script>
import Select from "../BaseComponents/Select/Select";
import {
  defineComponent, ref
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: "CategoryItemsPerPage",
  components: {
    Select
  },
  props: {
    doChangeItemsPerPage: {
      type: Function,
      require: true
    },
    itemsPerPage: {
      type: Number,
      require: true
    }
  },
  setup(props) {
    const itemsCount = ref(props.itemsPerPage || 12);

    if (itemsCount.value < 12) {
      itemsCount.value = 12;
    }

    if (itemsCount.value > 12 && itemsCount.value < 24) {
      itemsCount.value = 24;
    }

    if (itemsCount.value > 24 && itemsCount.value < 36 || itemsCount.value > 36) {
      itemsCount.value = 36;
    }

    return {
      itemsCount
    }
  },
  data() {
    return {
      options: [
        {
          label: `12 ${this.$t('Items')}`,
          value: 12
        },
        {
          label: `24 ${this.$t('Items')}`,
          value: 24
        },
        {
          label: `36 ${this.$t('Items')}`,
          value: 36
        }
      ]
    }
  }
});
</script>
