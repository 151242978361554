






















import {
  computed,
  defineComponent, ref,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'Modal',
  props: {
    visible: {
      type: Boolean,
      require: true
    },
    closeBtn: {
      type: Boolean,
      default() {
        return false;
      }
    },
    type: {
      type: String,
      default() {
        return 'modal' //side
      }
    },
    topOffset: {
      type: Number,
      default(){
        return null;
      }
    }
  },
  methods: {
    clickOutside($event) {
      if($event.target.classList.value.indexOf('modal-window') > -1) {
        this.$emit('close');
      }
    }
  }
});
