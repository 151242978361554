import type { MetaInfo } from 'vue-meta';
import {useContext} from "@nuxtjs/composition-api";
import {useConfigStore} from "~/stores/config";

export const getMetaInfo = (page: any, isNoIndex: boolean = false): MetaInfo => {
  if (!page) {
    return null;
  }

  const configStore = useConfigStore();
  const {storeConfig} = configStore;

  const {
    //@ts-ignore
    design_head_default_title,
    //@ts-ignore
    design_head_title_prefix,
    //@ts-ignore
    design_head_title_suffix,
    //@ts-ignore
    design_head_default_description,
    //@ts-ignore
    design_head_default_keywords
  } = storeConfig;

  const seoTags: MetaInfo = {
    meta: [],
    link: [],
    script: []
  };

  if (page?.['product_seo_name'] || page?.['mw_seo_category_data']?.['category_seo_name'] || page?.['category_seo_name'] || page?.meta_title || page?.title || page?.name) {
    seoTags.title = page?.['product_seo_name'] || page?.['mw_seo_category_data']?.['category_seo_name'] || page?.['category_seo_name'] || page?.meta_title || page?.title || page?.name;
  }

  if(!seoTags?.title && design_head_default_title) {
    seoTags.title = design_head_default_title;
  }

  if(seoTags?.title) {
    seoTags.title = `${design_head_title_prefix ? `${design_head_title_prefix} ` : ''}${seoTags.title}${design_head_title_suffix ? ` ${design_head_title_suffix}` : ''}`;
  }

  if (page?.['mw_seo_category_data']?.meta_title || page?.meta_title || page?.title || page?.name || design_head_default_title) {
    seoTags.meta.push({
      hid: 'title',
      name: 'title',
      content: `${design_head_title_prefix ? `${design_head_title_prefix} ` : ''}${page?.['mw_seo_category_data']?.meta_title || page?.meta_title || page?.title || page?.name || design_head_default_title}${design_head_title_suffix ? ` ${design_head_title_suffix}` : ''}`,
    });
  }

  if (page?.['mw_seo_category_data']?.meta_description || page?.meta_description || design_head_default_description) {
    seoTags.meta.push({
      hid: 'description',
      name: 'description',
      content: page?.['mw_seo_category_data']?.meta_description || page.meta_description || design_head_default_description,
    });
  }
  if (page?.['mw_seo_category_data']?.meta_keyword || page?.meta_keyword || page?.meta_keywords || design_head_default_keywords) {
    seoTags.meta.push({
      hid: 'keywords',
      name: 'keywords',
      content: page?.['mw_seo_category_data']?.meta_keyword || page?.meta_keyword || page?.meta_keywords || design_head_default_keywords,
    });
  }
  if (isNoIndex) {
    seoTags.meta.push({
      hid: 'robots',
      name: 'robots',
      content: 'noindex, nofollow',
    });
  }

  return seoTags;
};

export const getMetaInfoVuePages = (page: any) => {
  const {i18n} = useContext();

  const configStore = useConfigStore();
  const {storeConfig} = configStore;

  const {
    //@ts-ignore
    design_head_default_title,
    //@ts-ignore
    design_head_title_prefix,
    //@ts-ignore
    design_head_title_suffix,
    //@ts-ignore
    design_head_default_description,
    //@ts-ignore
    design_head_default_keywords
  } = storeConfig;

  const seoTags = {
    meta: [],
  };

  if(page?.meta_title || page?.title || design_head_default_title) {
    seoTags['title'] = `${design_head_title_prefix ? `${design_head_title_prefix} ` : ''}${i18n.t(page?.meta_title || page?.title || design_head_default_title)}${design_head_title_suffix ? ` ${design_head_title_suffix}` : ''}`
  }

  if (page?.meta_description || design_head_default_description) {
    seoTags.meta.push({
      hid: 'description',
      name: 'description',
      content: page.meta_description || design_head_default_description,
    });
  }

  if (page?.meta_keyword || page?.meta_keywords || design_head_default_keywords) {
    seoTags.meta.push({
      hid: 'keywords',
      name: 'keywords',
      content: page?.meta_keyword || page?.meta_keywords || design_head_default_keywords,
    });
  }

  return seoTags;
}
