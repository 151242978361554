import {computed, ref, ssrRef, useContext, useFetch} from "@nuxtjs/composition-api";
import {ProductInterface} from "~/modules/GraphQL/types";
import {usePageStore} from "~/stores/page";
import {useCache} from "@vue-storefront/cache";
import {useUiHelpers} from "../../../useUiHelpers";
import {SortingModel, SortingOptionsValuesEnum} from "~/modules/catalog/category/composables/useFacet/sortingOptions";
import {useCart, Pagination, useFacet, useUiState, useWishlist, useUser} from "~/composables";
import {useTraverseCategory} from "~/modules/catalog/category/helpers/useTraverseCategory";
import facetGetters from "~/modules/catalog/category/getters/facetGetters";
import getSubCategoryContentFirstPart from '~/customQueries/queries/getSubCategoryContentFirstPart.gql';

const usePalmersCategory = () => {
  const context = useContext();

  const {
    isAuthenticated
  } = useUser();

  const {routeData} = usePageStore();
  const {addTags} = useCache();
  const uiHelpers = useUiHelpers();
  const cmsContent = ref({});
  const products = ssrRef<ProductInterface[]>([]);
  const sortBy = ref<SortingModel>({selected: '', options: []});
  const pagination = ref<Pagination>({});

  const categoryPageContent = ref<HTMLElement | null>(null);

  const {
    toggleFilterSidebar,
    changeToCategoryListView,
    changeToCategoryGridView,
    isCategoryGridView,
    isFilterSidebarOpen,
  } = useUiState();
  const {
    isInWishlist,
    addOrRemoveItem
  } = useWishlist();
  const {result, search} = useFacet();

  const {addItem: addItemToCart} = useCart();

  const {activeCategory, loadCategoryTree} = useTraverseCategory();
  const activeCategoryName = computed(() => activeCategory.value?.name || '');

  const categoryUid = routeData?.uid;

  const getPrefixes = (products) => {
    let prefix = '';

    if(routeData?.id) {
      prefix += `CAT_C_${routeData.id};`;
    }

    for(let p = 0; p < products.length; p++) {
      const product = products[p];
      if(product?.id) {
        prefix += `CAT_P_${product.id};`;
      }
      if(product?.related_products || product?.upsell_products || product?.crosssell_products) {
        const additionalProducts = product?.related_products || product?.upsell_products || product?.crosssell_products;
        for(let a = 0; a < additionalProducts.length; a++) {
          const additionalProduct = additionalProducts[a];
          if(additionalProduct?.id) {
            prefix += `CAT_P_${additionalProduct.id};`;
          }
        }
      }
    }

    return prefix ? prefix.slice(0, -1) : prefix;
  }

  const {fetch} = useFetch(async () => {
    const searchParams = {...uiHelpers.getFacetsFromURL()};

    if(searchParams?.['itemsPerPage'] > 36) {
      searchParams['itemsPerPage'] = 36;
    }

    if(searchParams?.['itemsPerPage'] < 12) {
      searchParams['itemsPerPage'] = 12;
    }

    if (categoryUid) {
      searchParams['category_uid'] = categoryUid;
    }

    if (context.params.value.term) {
      searchParams['term'] = context.params.value.term;
    }

    if(routeData?.updated_at) {
      searchParams['updated'] = routeData.updated_at;
    }

    if(
      searchParams.sort === SortingOptionsValuesEnum.DEFAULT
      && context?.route.value?.name?.indexOf('search') > -1
    ) {
      searchParams['sort'] = 'search';
    }

    await search(searchParams)

    products.value = facetGetters.getProducts(result.value) || [];
    sortBy.value = facetGetters.getSortOptions(result.value);
    pagination.value = facetGetters.getPagination(result.value);

    if (categoryUid) {
      const promises = [
        context.$vsf.$magento.api.customQuery({
          query: getSubCategoryContentFirstPart,
          queryVariables: {
            category_uid: Array.isArray(routeData.uid) ? routeData.uid[0] : routeData.uid
          }
        })
      ];

      if (!activeCategory.value) {
        // @ts-ignore
        promises.push(loadCategoryTree());
      }

      const [getSubCategoryContentFirstPartData] = await Promise.all(promises);

      cmsContent.value = getSubCategoryContentFirstPartData?.data?.['categories']?.items?.[0];


      const tags = [{
        prefix: 'redis_cache_prefix',
        value: getPrefixes(products.value)
      }];

      addTags(tags);
    }
  })

  const isPriceLoaded = ref(true);

  const goToPage = (page: number) => {
    uiHelpers.changePage(page, false);
    fetch();
    //@ts-ignore
    categoryPageContent.value?.$refs.productContainerElement.scrollIntoView();
  };

  const doChangeItemsPerPage = (itemsPerPage: number) => {
    uiHelpers.changeItemsPerPage(itemsPerPage, false);
    goToPage(0);
  };

  const onReloadProducts = () => {
    goToPage(0);
    //@ts-ignore
    categoryPageContent.value?.$refs.productContainerElement.scrollIntoView();
  };

  const itemsPerPage = computed(() => products.value?.length);

  return {
    isPriceLoaded,
    uiHelpers,
    toggleFilterSidebar,
    isCategoryGridView,
    changeToCategoryListView,
    changeToCategoryGridView,
    isFilterSidebarOpen,
    addItemToCart,
    addItemToWishlist: addOrRemoveItem,
    pagination,
    products,
    sortBy,
    cmsContent,
    activeCategoryName,
    routeData,
    doChangeItemsPerPage,
    onReloadProducts,
    goToPage,
    categoryPageContent,
    isAuthenticated,
    isInWishlist,
    itemsPerPage
  };
};

export default usePalmersCategory;
