



















































































































































import {DelayHydration} from 'nuxt-delay-hydration/dist/components'
import {
  SfHeading,
} from '@storefront-ui/vue';
import {
  defineComponent,
} from '@nuxtjs/composition-api';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';

import CategoryNavbar from "~/components/Palmers/Category/Navbar/CategoryNavbar.vue";
import CategoryBreadcrumbs from "~/components/Palmers/Category/Breadcrumbs/CategoryBreadcrumbs.vue";
import Modal from '~/components/Palmers/BaseComponents/Modal/Modal.vue';
import CategoryPagination from "~/components/Palmers/Category/CategoryPagination/CategoryPagination.vue";
import CategoryItemsPerPage from "~/components/Palmers/Category/CategoryItemsPerPage.vue";
import PageBuilder from "~/components/Palmers/PageBuilder/PageBuilder.vue";
import {usePageBuilder} from "~/composables/Palmers";
import CategoryProductGrid from '~/components/Palmers/Category/CategoryProductGrid.vue';
import CategoryFilters from '~/components/Palmers/Category/Filter/CategoryFilters.vue';

export default defineComponent({
  name: 'CategoryPageContent',
  components: {
    Modal,
    CategoryFilters,
    CategoryProductGrid,
    CategoryNavbar,
    CategoryBreadcrumbs,
    CategoryPagination,
    CategoryEmptyResults: () => import('~/modules/catalog/category/components/CategoryEmptyResults.vue'),
    PageBuilder,
    DelayHydration,
    SfHeading,
    SkeletonLoader,
    CategoryItemsPerPage
  },
  transition: 'fade',
  data() {
    return {
      visible: false,
      isScrolled: false,
      elementPosition: null,
      loaded: false
    }
  },
  props: {
    isShowProducts: {
      type: Boolean,
      default() {
        return true
      }
    },
    sortBy: {
      type: Object,
      require: true
    },
    pagination: {
      type: Object,
      require: true
    },
    fetchState: {
      type: Object,
      require: true
    },
    onReloadProducts: {
      type: Function,
      require: true
    },
    doChangeItemsPerPage: {
      type: Function,
      require: true
    },
    itemsPerPage: {
      type: Number,
      require: true
    },
    routeData: {
      type: Object,
      require: true
    },
    toggleFilterSidebar: {
      type: Function,
      require: true
    },
    getCatLink: {
      type: Function,
      require: true
    },
    products: {
      type: Array,
      require: true
    },
    isPriceLoaded: {
      type: Boolean,
      require: true
    },
    addItemToWishlist: {
      type: Function,
      require: true
    },
    isAuthenticated: {
      type: Boolean,
      require: true
    },
    isProductInWishlist: {
      type: Function,
      require: true
    },
    addItemToCart: {
      type: Function,
      require: true
    },
    goToPage: {
      type: Function,
      require: true
    },
    isFilterSidebarOpen: {
      type: Boolean,
      require: true
    },
    isSearch: {
      type: Boolean,
      default: () => false
    },
    term: {
      type: String
    },
    cmsContent: {
      type: Object
    }
  },
  setup() {
    const {isPageBuilder} = usePageBuilder();

    return {
      isPageBuilder
    }
  },
  methods: {
    toggleModal(isShow) {
      this.visible = isShow;
    },
    handleScroll() {
      // Check if the window width is less than 789px before proceeding
      if (window.innerWidth < 789) {
        if (this.elementPosition === null && this.$refs.productContainerElement) {
          const element = this.$refs.productContainerElement as HTMLElement;
          this.elementPosition = element.getBoundingClientRect().top + window.scrollY - 60;
        }
        const top = window.scrollY || window.pageYOffset;
        this.isScrolled = top >= (this.elementPosition - 60);
      } else {
        this.isScrolled = false;
      }
    },
    addScrollListener() {
      if (window.innerWidth < 789) {
        window.addEventListener('scroll', this.handleScroll);
      }
    },
    removeScrollListener() {
      window.removeEventListener('scroll', this.handleScroll);
    },
    getContentStyle() {
      const style = {};

      //@ts-ignore
      if (this.routeData?.description && this.checkDisplayType()) {
        //@ts-ignore
        if (this.isPageBuilder(this.routeData.description)) {
          style['margin-top'] = 0;
        }
      }

      return style;
    },
    checkDisplayType() {
      //@ts-ignore
      return this.routeData?.['display_mode'] !== 'PRODUCTS'
    }
  },
  mounted() {
    this.$nextTick(() => {
      const element = this.$refs.productContainerElement as HTMLElement;
      if (element) {
        this.elementPosition = element.getBoundingClientRect().top + window.scrollY - 60;
      }
      this.addScrollListener();
    });
  },
  beforeDestroy() {
    this.removeScrollListener();
  }
});

