





































import {defineComponent, PropType, toRefs} from '@nuxtjs/composition-api';
import {useCart, useImage} from '~/composables';
import type {Product} from '~/modules/catalog/product/types';

import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import {useProductsWithCommonProductCardProps} from '~/modules/catalog/category/components/views/useProductsWithCommonCardProps';
import {useUrl} from "~/composables/Palmers";
export default defineComponent({
  components: {
    ProductItem: () => import("~/components/Palmers/Product/Item/ProductItem.vue"),
    SkeletonLoader
  },
  props: {
    products: {
      type: Array as PropType<Product[]>,
      required: true,
    },
    pricesLoaded: Boolean,
    loading: Boolean,
    addItemToWishlist: {
      type: Function,
      require: true
    },
    isAuthenticated: {
      type: Boolean,
      require: true
    },
    isProductInWishlist: {
      type: Function,
      require: true
    },
    addItemToCart: {
      type: Function,
      require: true
    }
  },
  setup(props) {
    const {canAddToCart, isInCart: isProductInCart} = useCart();
    const {imageSizes: {productCard: imageSize}} = useImage();
    const {products} = toRefs(props);
    const {productsWithCommonProductCardProps} = useProductsWithCommonProductCardProps(products);
    const {getProductUrl} = useUrl();
    return {
      imageSize,
      productsWithCommonProductCardProps,
      canAddToCart,
      isProductInCart,
      getProductUrl
    };
  },
  methods: {
    addSchema() {
      if (process.client && this.products.length) {
        const items = [];
        let baseUrl = this.$i18n['__baseUrl'];

        if (baseUrl[baseUrl.length - 1] === '/') {
          baseUrl = baseUrl.slice(0, -1);
        }

        for (let p = 0; p < 12; p++) {
          if (!this.products?.[p]) continue;
          items.push({
            "@type": "ListItem",
            "position": p + 1,
            "url": `${baseUrl}${this.getProductUrl(this.products[p])}`
          })
        }

        return [{
          type: 'application/ld+json', hid: "ItemList", json: {
            "@context": "https://schema.org",
            "@type": "ItemList",
            "itemListElement": items
          }
        }]
      }

      return [];
    }
  },
  head() {
    return {
      //@ts-ignore
      script: this.addSchema()
    }
  }
});
