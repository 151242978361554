








import {
  defineComponent, useFetch, ref,
} from '@nuxtjs/composition-api';
import {SfBreadcrumbs} from '@storefront-ui/vue';
import {useUiHelpers} from '~/composables';
import {useTraverseCategory} from '~/modules/catalog/category/helpers/useTraverseCategory';
import {Breadcrumb} from '~/modules/catalog/types';

export default defineComponent({
  components: {SfBreadcrumbs},
  props: {
    addSchema: {
      type: Boolean,
      default: () => false
    }
  },
  setup() {
    const {getCatLink} = useUiHelpers();

    const {
      categoryAncestors,
    } = useTraverseCategory();
    const breadcrumbs = ref<Breadcrumb[]>([]);

    useFetch(async () => {
      categoryAncestors.value.map((category) => (breadcrumbs.value.push({
        text: category.name,
        link: getCatLink(category),
      })));
    });

    return {
      breadcrumbs,
    };
  },
  methods: {
    getSchema() {
      if (process.client && this.addSchema && this.breadcrumbs.length) {
        const items = [];

        let baseUrl = this.$i18n['__baseUrl'];

        if(baseUrl[baseUrl.length-1] === '/') {
          baseUrl = baseUrl.slice(0, -1);
        }

        for (let b = 0; b < this.breadcrumbs.length; b++) {
          items.push({
            "@type": "ListItem",
            "position": b + 1,
            "name": this.breadcrumbs[b].text,
            "item": `${baseUrl}${this.breadcrumbs[b].link}`
          })
        }

        return {
          type: 'application/ld+json', hid: "BreadcrumbList", json: {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": items
          }
        }
      }

      return false;
    }
  },
  mounted() {
    this.$nextTick(() => {
      const schema = this.getSchema();
      if (schema?.['json']) {

        const oldScript = document.getElementById("bread-crumbs-schema");

        if(oldScript) {
          oldScript.remove();
        }

        const script = document.createElement('script');
        script.type = schema['type'];
        script.id = 'bread-crumbs-schema';
        script.innerText = JSON.stringify(schema['json']);
        document.body.appendChild(script);
      }
    })
  }
});
